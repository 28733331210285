import React, { FC, useEffect } from "react";
import { navigate } from "gatsby";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import {
  CharitiesContainer,
  Description,
  InnerWrapper,
  Layout,
  Spacer,
  HeaderText,
  ButtonWrapper,
  LinksContainer,
  TwoColumns,
  FirstColumn,
  SecondColumn,
} from "./styles";
import CharityAllocation from "@Zakat/components/CharityAllocation/CharityAllocation.component";
import { useDispatch, useSelector } from "react-redux";
import { useZakatSummaryPage } from "../summary/ZakatSummaryPageProvider";
import useAnalytics from "@helpers/useAnalytics";
import { Text } from "@ifgengineering/component-library";
import ZakatImpactFundBreakdown from "@assets/images/zakat-impact-fund-breakdown.png";
import { charitySelector } from "@Zakat/store/charity/charity.selector";
import { updateCharityAllocation } from "@Zakat/store/charity/charity.action";

const CharityAllocationPage: FC = () => {
  const dispatch = useDispatch();
  const { setPage } = useZakatSummaryPage();

  const firePageViewAnalytics = useAnalytics("pageView");
  const charity = useSelector(charitySelector);

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({ eventName });
    };
    firePageView("zakat:charityAllocation_pageViewed");
  }, []);

  const handleInputChange = (value: string) => {
    console.log("handleInputChange", value);
    if (value === "") return;
    dispatch(updateCharityAllocation(Number.parseFloat(value)));
  };

  const handleSave = () => {
    navigate("/app/top-up");
  };

  const handleBack = () => {
    setPage("topup");
    navigate("/app/summary");
  };

  return (
    <Layout>
      <InnerWrapper>
        <div>
          <TwoColumns>
            <FirstColumn>
              <HeaderText>Donate to the IFG Zakat Impact Fund</HeaderText>
              <Description>
                Every year, IFG puts together a Zakat Guide of charities that we
                think are having the biggest impact on this Ummah. This year we
                have gone one further and set up a the IFG Zakat Impact Fund - a
                dedicated fund to donate to the most strategically impactful
                (and otherwise overlooked) areas for the Muslim community
              </Description>
              <br />
              <Description>
                <strong>
                  IFG takes no fees, and your donation goes straight to the
                  selected organisations. Please note if you adhere to narrower
                  interpretations of zakat eligibility you would consider only
                  60% of the Fund to be zakat eligible.
                </strong>
              </Description>
              <LinksContainer>
                <Text
                  type="S16"
                  color="BLUE600"
                  as="a"
                  target="_blank"
                  href="https://www.islamicfinanceguru.com/wp-content/uploads/2025/02/Zakat-Impact-Fund-26-Feb-2025.pdf"
                >
                  → IFG Zakat Impact Fund Details
                </Text>
              </LinksContainer>
            </FirstColumn>
            <SecondColumn>
              <img src={ZakatImpactFundBreakdown} />
            </SecondColumn>
          </TwoColumns>
          <br />
          <CharitiesContainer>
            <CharityAllocation
              testId="charity-allocation-card"
              key={charity.id}
              name={charity.displayName}
              logoURL={charity.logoKey}
              allocation={charity.allocation}
              onBlur={handleInputChange}
            />
          </CharitiesContainer>
        </div>
      </InnerWrapper>
      <InnerWrapper>
        <ButtonWrapper>
          <Spacer />
          <FormButton
            text="Save & Continue"
            onClick={handleSave}
            active={true}
            leftArrowOnClick={handleBack}
            leftArrowLabel="Back"
          />
        </ButtonWrapper>
      </InnerWrapper>
    </Layout>
  );
};

export default CharityAllocationPage;
