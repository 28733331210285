import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import CurrencyInput from "react-currency-input-field";
import CardContainer from "@legacyComponents/CardContainer/CardContainer.component";
import { theme } from "../../../styles/legacy-theme";

const Name = styled.div`
  color: ${theme.colors.Font02};
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: regular;
  letter-spacing: 0em;
  line-height: 1.5rem;
  text-align: left;
`;

const Text = styled.p<{
  hasError?: boolean;
}>`
  color: ${({ hasError }) =>
    hasError ? theme.colors.ErrorRed : theme.colors.Font02};
  font-size: 0.75rem;
  margin-bottom: ${({ hasError }) => (hasError ? "0" : "0.5rem")};
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const CharityInfoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 1rem;
  justify-content: flex-start;

  @media (max-width: 900px) {
    margin-bottom: 1rem;
  }
`;

const InputContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  & input[type="text"] {
    opacity: 0;
    color: transparent;
    width: 100%;
    font-size: 1rem;
    box-sizing: border-box;
    height: 3rem;
    border-radius: 12px;
    padding: 13px;
    outline: transparent;
  }
`;

const LogoContainer = styled.div`
  width: 72px;
  height: 72px;
  min-width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessageContainer = styled.div`
  & > p {
    position: absolute;
    width: 280px;
    text-align: right;
  }
`;

const Logo = styled.img`
  width: 100%;
`;

const PerCentValue = styled.div`
  font-size: 1.18rem;
  font-weight: 600;
  color: ${theme.colors.Font02};
`;

const PerCentSymbol = styled.div`
  font-size: 1.18rem;
  color: ${theme.colors.Font02};
`;

const blink = keyframes`
 0% { color: transparent; }
 50% { color:${theme.colors.Font01}; }
`;

const BlinkyCaret = styled.span`
  font-weight: 100;
  font-size: 1.18rem;
  margin: 0 -2px 0 -2px;
  animation: 1s ${blink} step-end infinite;
`;

type FakeInputProp = {
  error: boolean;
};

const FakeInput = styled.div<FakeInputProp>`
  border-radius: 12px;
  border: 1px solid
    ${({ error }) => (error ? theme.colors.ErrorRed : theme.colors.Grey02)};
  box-sizing: border-box;
  display: flex;
  font-weight: 600;
  justify-content: flex-start;
  overflow: hidden;
  padding: 6px 12px;
  position: absolute;
  width: 100px;
`;

type CharityAllocationProps = {
  name?: string;
  logoURL?: string;
  allocation?: number;
  errorMessage?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  testId?: string;
};

const CharityAllocation = ({
  name,
  logoURL,
  allocation,
  errorMessage,
  onChange,
  onBlur,
  testId,
}: CharityAllocationProps): JSX.Element => {
  const defaultValue = "0";
  const [value, setValue] = useState<string>(defaultValue);
  const [showBlink, setShowBlink] = useState<boolean>(false);

  const onChangeHandler = (imputedValue: string) => {
    const previousValue = value || "0";
    const valueToConsider =
      parseFloat(imputedValue) > 100 ? previousValue : imputedValue;

    if (onChange) {
      onChange(valueToConsider);
    }
    setValue(valueToConsider);
  };

  const onConfirmChange = () => {
    if (onBlur) {
      onBlur(value || "0");
    }
  };

  useEffect(() => {
    setValue(allocation?.toString() || defaultValue);
  }, [allocation]);

  return (
    <CardContainer testId={testId}>
      <ContentWrapper>
        <CharityInfoContainer>
          <LogoContainer>
            <Logo src={logoURL} />
          </LogoContainer>
          <Name>{name}</Name>
        </CharityInfoContainer>
        <InputContainer>
          <div>
            <FakeInput error={Boolean(errorMessage)}>
              <PerCentValue>{value}</PerCentValue>
              {showBlink && <BlinkyCaret>|</BlinkyCaret>}
              <PerCentSymbol>%</PerCentSymbol>
            </FakeInput>
            <CurrencyInput
              defaultValue={0}
              decimalsLimit={2}
              value={value}
              onClick={() => {
                setShowBlink(true);
                onChangeHandler(value === defaultValue ? "" : value);
              }}
              onFocus={() => {
                setShowBlink(true);
              }}
              onBlur={() => {
                setShowBlink(false);
                onChangeHandler(value || defaultValue);
                onConfirmChange();
              }}
              onValueChange={(fieldValue) => onChangeHandler(fieldValue || "")}
            />
          </div>
          <ErrorMessageContainer>
            {errorMessage && (
              <Text hasError={!!errorMessage}>{errorMessage}</Text>
            )}
          </ErrorMessageContainer>
        </InputContainer>
      </ContentWrapper>
    </CardContainer>
  );
};

export default CharityAllocation;
